import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Message = styled.span`
  font-size: 1.2rem;
  color: #c51b1b;
`
const ErrorMsg = ({ className, children }) => {
  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <Message className={className}>{children}</Message>
}
ErrorMsg.propTypes = {
  children: PropTypes.string,
}
export default ErrorMsg
