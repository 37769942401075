import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import * as FormElements from 'app/components/form/FormElements'
import { Divider } from 'app/components/Panel.jsx'
import Label from 'app/components/form/Label'
import Input from 'app/components/form/Input'
import SubmitButton from 'app/components/SubmitButton'
import ErrorMsg from 'app/components/ErrorMsg'
import { getErrorMsg } from 'app/pages/login/selectors'
import { setErrorMessage } from 'app/pages/login/actions'
import { errorMessages } from 'utils/errorMessages'

const DomainForm = ({
  handleSubmit,
  hideLoginTitle,
  errorMessage,
  setErrorMessage,
}) => {
  const domainKey = 'knownBy'
  const [domainValue, setDomainValue] = useState({ knownBy: '' })

  const checkCompanyName = () => {
    if (domainValue[domainKey].length < 1) {
      setErrorMessage({ message: errorMessages.domainForm.emptyForm })
    } else {
      handleSubmit(domainValue)
    }
  }

  return (
    <>
      <FormElements.BackButton marginBottom='10rem' onClick={hideLoginTitle} />
      <FormElements.LoginRow>
        <Label htmlFor={domainKey}>Company name</Label>
        <Input
          name={domainKey}
          type='text'
          autoComplete={domainKey}
          autoFocus
          required
          value={domainValue[domainKey]}
          onChange={(event) => setDomainValue({ knownBy: event.target.value })}
        />
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        {errorMessage && (
          <ErrorMsg>
            {errorMessage !== errorMessages.changeErrorMessage
              ? errorMessage
              : errorMessages.domainForm.companyName}
          </ErrorMsg>
        )}
        {!errorMessage && <Divider height={'1.2rem'} />}
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={checkCompanyName} primary>
          Next
        </SubmitButton>
      </FormElements.LoginRow>
    </>
  )
}

DomainForm.propTypes = {
  handleSubmit: PropTypes.func,
  hideLoginTitle: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
}
const mapStateToProps = (state) => ({
  errorMessage: getErrorMsg(state),
})

export default connect(mapStateToProps, {
  setErrorMessage,
})(DomainForm)
