import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LoginPanelHolder } from 'app/components/Panel.jsx'

import { setEnterClicked } from 'app/pages/login/actions'
const enterKeyCode = 13

const LoginPanel = ({ setEnterClicked, children }) => {
  const handleEnterClicked = (e) => {
    if (e.keyCode === enterKeyCode) {
      setEnterClicked()
    }
  }
  return (
    <LoginPanelHolder onKeyUpCapture={handleEnterClicked} focus={true}>
      {children}
    </LoginPanelHolder>
  )
}

LoginPanel.propTypes = {
  children: PropTypes.array,
  setEnterClicked: PropTypes.func,
}

export default connect(null, {
  setEnterClicked,
})(LoginPanel)
