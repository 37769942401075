// These are kind of factories for creating the
// action creators for the different plugins.
import { normalize } from 'normalizr'

import * as rest from 'app/api/rest'
import { setErrorMessage } from '../app/pages/login/actions'
import { errorMessages } from './errorMessages'

const sessionStartPath = '/'

export const get =
  ({
    constants: { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE },
    endpoint,
    schema,
    name,
    params,
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REQUEST,
    })
    try {
      const searchParams = new URLSearchParams(params).toString()
      const url =
        Object.keys(params).length === 0
          ? endpoint
          : `${endpoint}?${searchParams}`

      const response = await rest.fetchData(url)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: FETCH_SUCCESS,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      dispatch({
        type: FETCH_FAILURE,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const getNoContent =
  ({
    constants: { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE },
    endpoint,
    schema,
    name,
    params,
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REQUEST,
    })
    try {
      const searchParams = new URLSearchParams(params).toString()
      const url =
        Object.keys(params).length === 0
          ? endpoint
          : `${endpoint}?${searchParams}`

      await rest.fetchDataNoContent(url)

      dispatch({
        type: FETCH_SUCCESS,
      })
      return Promise.resolve()
    } catch (error) {
      dispatch({
        type: FETCH_FAILURE,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const getExternal =
  ({
    constants: { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE },
    endpoint,
    schema,
    name,
    token,
    params,
    contentType = 'application/json',
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REQUEST,
    })
    try {
      const searchParams = new URLSearchParams(params).toString()
      const url =
        Object.keys(params).length === 0
          ? endpoint
          : `${endpoint}?${searchParams}`

      const response = await rest.fetchExternalData(url, token, contentType)

      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: FETCH_SUCCESS,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      dispatch({
        type: FETCH_FAILURE,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const getExternalNoContent =
  ({
    constants: { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE },
    endpoint,
    schema,
    name,
    token,
    params,
    contentType = 'application/json',
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REQUEST,
    })
    try {
      const searchParams = new URLSearchParams(params).toString()
      const url =
        Object.keys(params).length === 0
          ? endpoint
          : `${endpoint}?${searchParams}`

      await rest.fetchExternalDataNoContent(url, token, contentType)

      dispatch({
        type: FETCH_SUCCESS,
      })
      return Promise.resolve()
    } catch (error) {
      dispatch({
        type: FETCH_FAILURE,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const getWithConstants =
  ({
    fetchConstant,
    failureConstant,
    successConstant,
    endpoint,
    schema,
    name,
  }) =>
  async (dispatch) => {
    dispatch({
      type: fetchConstant,
    })
    try {
      const response = await rest.fetchData(endpoint)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: successConstant,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      dispatch({
        type: failureConstant,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const getWithAdditionalInfo =
  ({
    constants: { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE },
    endpoint,
    schema,
    name,
    addInfo,
  }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_REQUEST,
    })
    try {
      const response = await rest.fetchData(endpoint)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: FETCH_SUCCESS,
        response: normalizedResponse,
        addInfo,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      dispatch({
        type: FETCH_FAILURE,
        message: error.message,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const postWithConstant =
  ({
    successConstant,
    requestConstant = null,
    failureConstant = null,
    endpoint,
    schema,
    message,
    item,
    getsJSON = true,
  }) =>
  async (dispatch) => {
    if (requestConstant) {
      dispatch({
        type: requestConstant,
      })
    }

    try {
      const response = await rest.postData(endpoint, item, getsJSON)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: successConstant,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      if (failureConstant) {
        dispatch({
          type: failureConstant,
        })
      }
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const post =
  ({
    constant: { ADD_SUCCESS },
    endpoint,
    schema,
    message,
    item,
    getsJSON = true,
  }) =>
  async (dispatch) => {
    try {
      const response = await rest.postData(endpoint, item, getsJSON)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: ADD_SUCCESS,
        response: normalizedResponse,
      })

      return Promise.resolve(normalizedResponse)
    } catch (error) {
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const postExternal =
  ({
    constants: { POST_SUCCESS, POST_FAILURE },
    endpoint,
    schema,
    message,
    item,
    getsJSON = true,
  }) =>
  async (dispatch) => {
    try {
      const response = await rest.postExternalData(endpoint, item, getsJSON)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: POST_SUCCESS,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      dispatch({
        type: POST_FAILURE,
      })
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }

export const postWithoutSnackbar =
  ({
    constant: { ADD_SUCCESS },
    endpoint,
    schema,
    message,
    item,
    getsJSON = true,
  }) =>
  async (dispatch) => {
    try {
      const response = await rest.postData(endpoint, item, getsJSON)
      const normalizedResponse = schema
        ? normalize(response, schema)
        : {
            entities: {},
            result: response,
          }
      dispatch({
        type: ADD_SUCCESS,
        response: normalizedResponse,
      })
      return Promise.resolve(normalizedResponse)
    } catch (error) {
      console.error(error.message)

      if (error.code === 400) {
        dispatch(setErrorMessage({ message: 'Authentication error' }))
        window.location.href = sessionStartPath
      } else if (error.code > 500) {
        dispatch(
          setErrorMessage({ message: errorMessages.generalErrorMessage })
        )
      } else if (error.code === 429) {
        dispatch(setErrorMessage({ message: errorMessages.lockedAccount }))
      } else if (error.code > 400 && error.code < 500) {
        dispatch(setErrorMessage())
      }
      return Promise.reject(error)
    }
  }
