import React from 'react'
import styled from 'styled-components'

import * as FormElements from 'app/components/form/FormElements'
import { passViewPath } from 'app/pages/login/constants'
import { useNavigate } from 'react-router-dom'

const Link = styled.a`
  display: block;
  margin: 0 auto;
  text-align: center;
  color: #0178d4;
  font-size: 2rem;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const ConfirmChangedPassword = () => {
  const navigate = useNavigate()

  return (
    <FormElements.LoginRow>
      <Link onClick={() => navigate(passViewPath)}>Go to login page!</Link>
    </FormElements.LoginRow>
  )
}
ConfirmChangedPassword.propTypes = {}

export default ConfirmChangedPassword
