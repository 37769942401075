export const icons = {
  BOOKABLE: 'bookable1',
  BUILDING: 'building',
  CALENDAR: 'calendar2',
  CIRCLED_RIGHT: 'circled_chevron_right',
  CLOSE: 'delete',
  DESK_OLD: 'desk1',
  DESK: 'space',
  DOWN: 'fa-chevron-down',
  FILLED_USER: 'user_location_filled',
  FLAG: 'flag',
  FLOORS: 'floors',
  FLOWSCAPE: 'flowscape-logo',
  FORWARD: 'forward2',
  EXPAND: 'expand-arrow',
  LOGOUT: 'shutdown',
  MINUS: 'fa-minus2',
  NOT_VISIBLE: 'invisible',
  PLUS: 'fa-plus',
  PROP_PHONE: 'prop_phone',
  PROP_SOFA: 'prop_sofa',
  PROP_PROJ: 'prop_proj',
  PROP_DESK_DUAL_SCREEN: 'prop_desk_screen_dual',
  PROP_SCREEN: 'prop_screen',
  PROP_DESK_MAC: 'prop_desk_mac',
  PROP_DESK_SINGLE_SCREEN: 'prop_desk_screen_single',
  PROP_DESK_ERGONOMIC: 'prop_desk_ergonomic',
  PROP_VIDEO: 'prop_video',
  PROP_CURVED_SCREEN: 'prop_curved_screen',
  RIGHT: 'fa-chevron-right',
  ROOM: 'room1',
  ROTATE_LEFT: 'fa-rotate-left',
  ROTATE_RIGHT: 'fa-rotate-right',
  SEARCH: 'fa-search',
  SHOW_ALL: 'show-all',
  SPINNER: 'fa-spinner',
  TELE_TWO_DESK: 'tele2-desk',
  TELE_TWO_ROOM: 'tele2-room',
  THREE_D_VIEW: 'd-view2',
  TWO_D_VIEW: 'd-view',
  UNDO: 'fa-rotate-left',
  UPDATE: 'cloud-download',
  USER: 'user',
  USERS: 'users',
  VISIBLE: 'visible',
  WAYFIND: 'wayfind1',
  PROFILE: 'profile_white',
  GEO_FENCE: 'geo_fence_white',
  CALENDAR_B: 'calendar_white',
  PARKING: 'desk_parking',
  PARKING_EL: 'desk_parking_electric',
  CLOCK: 'clock',
  DESK_LOCKER: 'desk_locker',
  LEFT_ARROW: 'left',
  SETTINGS: 'cog',
  FLOWPLANNER: 'user-check',
  PREVIOUS: 'previous',
  RESET: 'reset',
}
