import React from 'react'
import PropTypes from 'prop-types'

import LoginPanel from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import EmailForm from 'app/pages/login/EmailForm'
import PasswordForm from 'app/pages/login/PasswordForm'
import CodeForm from 'app/pages/login/CodeForm'
import RequestPassForm from 'app/pages/login/RequestPassForm'
import ConfirmPassChangedPage from 'app/pages/login/ConfirmPassChangedPage'
import {
  crendentialsViewPath,
  codeViewPath,
  newPassViewPath,
  changedPassViewPath,
  passViewPath,
} from 'app/pages/login/constants'

const RealmCredentialsLogin = ({ showPage }) => {
  const currentPage = () => {
    switch (showPage) {
      case crendentialsViewPath:
        return <EmailForm />
      case passViewPath:
        return <PasswordForm />
      case codeViewPath:
        return <CodeForm />
      case newPassViewPath:
        return <RequestPassForm />
      case changedPassViewPath:
        return <ConfirmPassChangedPage />
      default:
        return <EmailForm />
    }
  }

  return (
    <LoginPanel>
      {currentPage()}
      <FlexSpacer />
    </LoginPanel>
  )
}
RealmCredentialsLogin.propTypes = {
  showPage: PropTypes.string,
}

export default RealmCredentialsLogin
