import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { config } from 'app/api/public'

import * as FormElements from 'app/components/form/FormElements'
import { getAvailableREALMS } from 'app/pages/login/selectors'
import { getAvailableRealms } from 'app/pages/login/actions'
// import { StyledLink } from 'app/pages/login/Button'
import SubmitButton from 'app/components/SubmitButton'

// const LinkBtn = styled(StyledLink)`
//   text-align: center;
//   font-size: 1.6rem;
//   margin: 1.5rem 0 0 0;
// `
const LinkContainer = styled.div`
  max-height: 60%;
  overflow: auto;
  width: 90%;
  margin: 0 auto;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fffcfa;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fffcfa;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`

const RealmsList = ({ getAvailableRealms, realms }) => {
  useEffect(() => {
    getAvailableRealms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectRealm = async (realm_uuid) => {
    const central_host = await config('central_hostname')
    const api_host =
      process.env.NODE_ENV === 'production'
        ? window.location.host
        : central_host
    window.location = `https://${api_host}/reception/realm/select/${realm_uuid}`
  }

  return (
    <LinkContainer>
      {realms.map((realm) => (
        <FormElements.LoginRow key={realm.uuid}>
          <SubmitButton onClick={() => selectRealm(realm.uuid)} primary>
            {realm.name}
          </SubmitButton>
        </FormElements.LoginRow>
      ))}
    </LinkContainer>
  )
}
RealmsList.propTypes = {
  realms: PropTypes.array,
  getAvailableRealms: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realms: getAvailableREALMS(state),
})

export default connect(mapStateToProps, {
  getAvailableRealms,
})(RealmsList)
