export const errorMessages = {
  noMessage: null,
  generalErrorMessage:
    'Ooops looks like something went wrong. Please try again in a few minutes or contact your administrator. (500)',
  changeErrorMessage: 'Error message',
  lockedAccount:
    'Too many login attempts. For security reasons your account has been temporarily locked. Please try again later.',
  domainForm: {
    companyName: 'Sorry, we don`t recognize this company name',
    emptyForm: 'Please enter a company name',
  },
  enterEmail: {
    wrongFormat:
      'Please enter your email address in format yourname@example.com',
    userDoesNotExist: 'Sorry, we don`t recognize this email',
  },
  enterPass: {
    wrongPassword:
      'The password you entered is incorrect. Please try again or click ‘Request new password’ to reset it',
    emptyPass: 'Please enter a password',
  },
  enterCode: {
    wrongCode:
      'The code was incorrect. Please try again or request a new code by going back and clicking “Sign in with verification code” again',
    emptyCode: 'Please enter a code',
  },
  enterNewPassword: {
    newPassFormat:
      'Your password must be at least 8 characters long, contain at least one number and one symbol and have a mixture of uppercase and lowercase letters.',
  },
}
