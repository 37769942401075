import fetch from 'isomorphic-fetch'

import { config } from './public'

const receptionUrl = window.location.host
export const fetchData = async (endpoint) => {
  const central_host = await config('central_hostname')
  const api_host =
    process.env.NODE_ENV !== 'production' ? central_host : receptionUrl
  const api_url = `https://${api_host}/reception`
  const response = await fetch(`${api_url}${endpoint}`, {
    credentials: 'include',
    redirect: 'manual',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  return errorResponseHandler(response)
}

export const fetchDataNoContent = async (endpoint) => {
  const central_host = await config('central_hostname')
  const api_host =
    process.env.NODE_ENV !== 'production' ? central_host : receptionUrl
  const api_url = `https://${api_host}/reception`
  const response = await fetch(`${api_url}${endpoint}`, {
    credentials: 'include',
    redirect: 'manual',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  if (response.ok) {
    return Promise.resolve()
  }
  return errorResponseHandler(response)
}

export const fetchExternalData = async (endpoint, accessToken, contentType) => {
  const response = await fetch(endpoint, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${accessToken}`,
    },
  })
  if (response.ok) {
    const data = await (contentType === 'application/json'
      ? response.json()
      : response.blob())

    return Promise.resolve(data)
  }
  return errorResponseHandler(response)
}

export const fetchExternalDataNoContent = async (
  endpoint,
  accessToken,
  contentType
) => {
  const response = await fetch(endpoint, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${accessToken}`,
    },
  })
  if (response.ok) {
    return Promise.resolve()
  }
  return errorResponseHandler(response)
}

export const fetchBlob = async (endpoint) => {
  const central_host = await config('central_hostname')
  const api_host =
    process.env.NODE_ENV !== 'production' ? central_host : receptionUrl
  const api_url = `https://${api_host}/reception`
  const response = await fetch(`${api_url}${endpoint}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/octet-stream',
    },
  })
  if (response.ok) {
    const objectURL = response.text()
    return Promise.resolve(objectURL)
  }
  return errorResponseHandler(response)
}

export const postData = async (endpoint, data, getsJSON = true) => {
  const central_host = await config('central_hostname')
  const api_host =
    process.env.NODE_ENV !== 'production' ? central_host : receptionUrl
  const api_url = `https://${api_host}/reception`
  const response = await fetch(`${api_url}${endpoint}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.ok) {
    if (getsJSON) {
      const responseData = await response.json()
      return Promise.resolve(responseData)
    }
    return Promise.resolve(response)
  }
  return errorResponseHandler(response)
}

export const postExternalData = async (endpoint, data, getsJSON = true) => {
  const response = await fetch(`${endpoint}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  if (response.ok) {
    if (getsJSON) {
      const responseData = await response.json()
      return Promise.resolve(responseData)
    }
    return Promise.resolve(response)
  }
  return errorResponseHandler(response)
}

export const authenticateUser = async () => {
  const central_host = await config('central_hostname')
  const api_host =
    process.env.NODE_ENV !== 'production' ? central_host : receptionUrl
  const api_url = `https://${api_host}/reception`
  const response = await fetch(`${api_url}/authentication/logout`, {
    credentials: 'include',
    method: 'POST',
  })
  if (response.ok) {
    return Promise.resolve(response)
  }
  return errorResponseHandler(response)
}

export const errorResponseHandler = async (response) => {
  try {
    const error = await response.json()
    return Promise.reject(error)
  } catch (parseError) {
    const error = {
      message: 'Failed to read response',
      code: response.code,
    }
    return Promise.reject(error)
  }
}
