import styled from 'styled-components'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { StyledInput, Wrapper } from './Input'

import Icon from 'app/components/Icon'
import { icons } from 'app/components/icon/icons'

const PasswordWrapper = Wrapper.withComponent('div')

const ToggleButton = styled.button`
  position: absolute;
  top: 2.7rem;
  right: 2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  background: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${Icon} {
    color: ${(props) => props.theme.darkText};
  }
`

class Password extends PureComponent {
  render() {
    const {
      type,
      input,
      name,
      value,
      disabled,
      visible,
      toggleVisibility,
      ...rest
    } = this.props

    return (
      <PasswordWrapper>
        <StyledInput
          id={name}
          name={name}
          disabled={disabled}
          type={visible ? 'text' : 'password'}
          tabIndex='0'
          {...input}
          {...rest}
        />
        {value.length > 0 && (
          <ToggleButton
            type='button'
            onClick={toggleVisibility}
            disabled={disabled}
            tabIndex='-1'
          >
            <Icon name={visible ? icons.NOT_VISIBLE : icons.VISIBLE} />
          </ToggleButton>
        )}
      </PasswordWrapper>
    )
  }
}

Password.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  input: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  toggleVisibility: PropTypes.func,
}

export default Password
