export const DOMAIN_REQUEST = 'pages/login/DOMAIN_REQUEST'
export const DOMAIN_SUCCESS = 'pages/login/DOMAIN_SUCCESS'
export const DOMAIN_FAILURE = 'pages/login/DOMAIN_FAILURE'
export const CREDENTIALS_REQUEST = 'pages/login/CREDENTIALS_REQUEST'
export const CREDENTIALS_SUCCESS = 'pages/login/CREDENTIALS_SUCCESS'
export const CREDENTIALS_FAILURE = 'pages/login/CREDENTIALS_FAILURE'
export const REALM_INFO_REQUEST = 'pages/login/REALM_INFO_REQUEST'
export const REALM_INFO_SUCCESS = 'pages/login/REALM_INFO_SUCCESS'
export const REALM_INFO_FAILURE = 'pages/login/REALM_INFO_FAILURE'
export const REALM_OPTIONS_REQUEST = 'pages/login/REALM_OPTIONS_REQUEST'
export const REALM_OPTIONS_SUCCESS = 'pages/login/REALM_OPTIONS_SUCCESS'
export const REALM_OPTIONS_FAILURE = 'pages/login/REALM_OPTIONS_FAILURE'
export const GET_OPTIONS_REQUEST = 'pages/login/GET_OPTIONS_REQUEST'
export const GET_OPTIONS_SUCCESS = 'pages/login/GET_OPTIONS_SUCCESS'
export const GET_OPTIONS_FAILURE = 'pages/login/GET_OPTIONS_FAILURE'
export const GET_CONSENT_REQUEST = 'pages/login/GET_CONSENT_REQUEST'
export const GET_CONSENT_SUCCESS = 'pages/login/GET_CONSENT_SUCCESS'
export const GET_CONSENT_FAILURE = 'pages/login/GET_CONSENT_FAILURE'
export const ACCEPTANCE_SUCCESS = 'pages/login/ACCEPTANCE_SUCCESS'
export const ACCEPTANCE_FAILURE = 'pages/login/ACCEPTANCE_FAILURE'
export const VALIDATE_EMAIL_REQUEST = 'pages/login/VALIDATE_EMAIL_REQUEST'
export const VALIDATE_EMAIL_SUCCESS = 'pages/login/VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'pages/login/VALIDATE_EMAIL_FAILURE'
export const SET_ERROR_MESSAGE = 'pages/login/SET_ERROR_MESSAGE'
export const SET_EMAIL_VALUE = 'pages/login/SET_EMAIL_VALUE'
export const CLEAR_CREDENTIALS = 'pages/login/CLEAR_CREDENTIALS'
export const CLEAR_ERROR_MESSAGE = 'pages/login/CLEAR_ERROR_MESSAGE'
export const TRIGGER_CODE_REQUEST = 'pages/login/TRIGGER_CODE_REQUEST'
export const TRIGGER_CODE_SUCCESS = 'pages/login/TRIGGER_CODE_SUCCESS'
export const TRIGGER_CODE_FAILURE = 'pages/login/TRIGGER_CODE_FAILURE'
export const CODE_SUCCESS = 'pages/login/CODE_SUCCESS'
export const CODE_FAILURE = 'pages/login/CODE_FAILURE'
export const TRIGGER_NEW_PASS_REQUEST = 'pages/login/TRIGGER_NEW_PASS_REQUEST'
export const TRIGGER_NEW_PASS_SUCCESS = 'pages/login/TRIGGER_NEW_PASS_SUCCESS'
export const TRIGGER_NEW_PASS_FAILURE = 'pages/login/TRIGGER_NEW_PASS_FAILURE'
export const CLEAR_VALID_REQUEST = 'pages/login/CLEAR_VALID_REQUEST'
export const GET_REALMS_REQUEST = 'pages/login/GET_REALMS_REQUEST'
export const GET_REALMS_SUCCESS = 'pages/login/GET_REALMS_SUCCESS'
export const GET_REALMS_FAILURE = 'pages/login/GET_REALMS_FAILURE'
export const ENTER_NEW_PASS_SUCCESS = 'pages/login/ENTER_NEW_PASS_SUCCESS'
export const ENTER_NEW_PASS_FAILURE = 'pages/login/ENTER_NEW_PASS_FAILURE'
export const HANDLE_ENTER_CLICK = 'pages/login/HANDLE_ENTER_CLICK'
export const HANDLED_ENTER_CLICK = 'pages/login/HANDLED_ENTER_CLICK'

export const crendentialsViewPath = '/login/realm/validate-email'
export const realmViewPath = '/login/realm'
export const passViewPath = '/login/realm/password-login'
export const codeViewPath = '/login/realm/code'
export const newPassViewPath = '/login/realm/request_new_password'
export const changedPassViewPath = '/login/realm/changed_password'
export const enterPassViewPath = '/login/enter-password'
export const successfulyChangedViewPath = '/login/successfuly-changed'

export const SET_SELECTED_APP = 'pages/login/SET_SELECTED_APP'
