import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as FormElements from 'app/components/form/FormElements'
import Label from 'app/components/form/Label'
import Input from 'app/components/form/Input'
import SubmitButton from 'app/components/SubmitButton'
import { passViewPath } from 'app/pages/login/constants'
import { Divider } from 'app/components/Panel.jsx'
import {
  getUserName,
  getRealmInfo,
  getErrorMsg,
  getRequestIsValid,
} from 'app/pages/login/selectors'
import {
  clearErrorMessage,
  postCode,
  setErrorMessage,
  clearValidRequest,
} from 'app/pages/login/actions'
import ErrorMsg from 'app/components/ErrorMsg'
import { errorMessages } from 'utils/errorMessages'

const Title = styled.p`
  font-size: ${(props) => (props.subTitle ? '1.6rem' : '1.8rem')};
  font-weight: ${(props) => (props.subTitle ? '400' : '500')};
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: ${(props) =>
    props.subTitle ? '0 auto 2rem auto' : '0 auto 1rem auto'};
`

const CodeForm = ({
  realmInfo,
  userName,
  errorMessage,
  setErrorMessage,
  clearErrorMessage,
  requestIsValid,
  postCode,
  clearValidRequest,
}) => {
  const codeKey = 'code'
  let code = ''
  const navigate = useNavigate()

  useEffect(() => {
    if (requestIsValid) {
      const realmAddress = realmInfo.address
      const successful_login_url = `${realmAddress}/concierge/session/redirect`
      clearValidRequest()
      window.location = successful_login_url
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestIsValid])

  const openPassPath = () => {
    clearErrorMessage()
    navigate(passViewPath)
  }
  const loginWithCode = () => {
    const realmAddress = realmInfo.address
    if (code) {
      postCode(realmAddress, { code })
    } else {
      setErrorMessage({ message: errorMessages.enterCode.emptyCode })
    }
  }

  return (
    <>
      <FormElements.BackButton marginBottom={'4.5rem'} onClick={openPassPath} />
      <FormElements.LoginRow>
        <Title>Welcome {userName}!</Title>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <Title subTitle>The code is on it’s way</Title>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <Label htmlFor={codeKey}>Enter code from email</Label>
        <Input
          name={codeKey}
          type='text'
          autoComplete={codeKey}
          autoFocus
          required
          onChange={(event) => {
            code = event.target.value
          }}
        />
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        {errorMessage && (
          <ErrorMsg>
            {errorMessage !== errorMessages.changeErrorMessage
              ? errorMessage
              : errorMessages.enterCode.wrongCode}
          </ErrorMsg>
        )}
        {!errorMessage && <Divider height={'1.2rem'} />}
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={loginWithCode} primary>
          Sign in
        </SubmitButton>
      </FormElements.LoginRow>
      {/* </form> */}
    </>
  )
}
CodeForm.propTypes = {
  realmInfo: PropTypes.object,
  setErrorMessage: PropTypes.func,
  userName: PropTypes.string,
  errorMessage: PropTypes.string,
  clearCredentials: PropTypes.func,
  clearErrorMessage: PropTypes.func,
  postCode: PropTypes.func,
  requestIsValid: PropTypes.bool,
  clearValidRequest: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  userName: getUserName(state),
  errorMessage: getErrorMsg(state),
  requestIsValid: getRequestIsValid(state),
})

export default connect(mapStateToProps, {
  postCode,
  clearErrorMessage,
  setErrorMessage,
  clearValidRequest,
})(CodeForm)
