import React from 'react'
import styled, { keyframes } from 'styled-components'

const grow = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

const Wrapper = styled.span`
  display: inline-block;
  text-align: center;
`

const Bounce = styled.span`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
  background-color: #b0bec5;
  border-radius: 50%;
  display: inline-block;
  animation: ${grow} 1.4s infinite ease-in-out both;

  :nth-child(1) {
    animation-delay: -0.32s;
  }

  :nth-child(2) {
    animation-delay: -0.16s;
  }

  :nth-child(3) {
    animation-delay: 0s;
  }
`

const InlineLoading = () => (
  <Wrapper>
    <Bounce />
    <Bounce />
    <Bounce />
  </Wrapper>
)

export default InlineLoading
