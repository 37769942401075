import styled from 'styled-components'

export const BackgroundHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: ${(props) =>
    props.isPortal
      ? `url(${process.env.PUBLIC_URL}/flowscape_portal_background.jpg)`
      : `url(${process.env.PUBLIC_URL}/lights_table.jpg)`};
  background-size: cover;
  z-index: -1;
`
