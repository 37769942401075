import { combineReducers } from 'redux-immutable'

import {
  DOMAIN_FAILURE,
  DOMAIN_SUCCESS,
  CREDENTIALS_SUCCESS,
  CREDENTIALS_FAILURE,
  REALM_INFO_SUCCESS,
  REALM_INFO_FAILURE,
  REALM_OPTIONS_SUCCESS,
  REALM_OPTIONS_FAILURE,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  GET_CONSENT_SUCCESS,
  GET_CONSENT_FAILURE,
  ACCEPTANCE_SUCCESS,
  ACCEPTANCE_FAILURE,
  VALIDATE_EMAIL_REQUEST,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  GET_CONSENT_REQUEST,
  CREDENTIALS_REQUEST,
  GET_OPTIONS_REQUEST,
  REALM_OPTIONS_REQUEST,
  REALM_INFO_REQUEST,
  DOMAIN_REQUEST,
  SET_ERROR_MESSAGE,
  SET_EMAIL_VALUE,
  CLEAR_CREDENTIALS,
  TRIGGER_CODE_REQUEST,
  TRIGGER_CODE_SUCCESS,
  TRIGGER_CODE_FAILURE,
  TRIGGER_NEW_PASS_REQUEST,
  TRIGGER_NEW_PASS_SUCCESS,
  TRIGGER_NEW_PASS_FAILURE,
  CODE_SUCCESS,
  CODE_FAILURE,
  CLEAR_VALID_REQUEST,
  GET_REALMS_FAILURE,
  GET_REALMS_SUCCESS,
  ENTER_NEW_PASS_FAILURE,
  ENTER_NEW_PASS_SUCCESS,
  CLEAR_ERROR_MESSAGE,
  HANDLE_ENTER_CLICK,
  HANDLED_ENTER_CLICK,
  SET_SELECTED_APP,
} from './constants'

const domainIsValid = (state = false, action) => {
  switch (action.type) {
    case DOMAIN_SUCCESS:
      return true
    case DOMAIN_FAILURE:
      return false
    default:
      return state
  }
}

const realmInfo = (state = {}, action) => {
  switch (action.type) {
    case ENTER_NEW_PASS_SUCCESS:
      return {
        address: action.response.result.environmentAddress,
        name: action.response.result.environmentName,
      }
    case REALM_INFO_SUCCESS:
      return action.response.result
    case REALM_INFO_FAILURE:
    case ENTER_NEW_PASS_FAILURE:
      return {}
    default:
      return state
  }
}

const userName = (state = '', action) => {
  switch (action.type) {
    case ENTER_NEW_PASS_SUCCESS:
      return action.response.result.userName
    case VALIDATE_EMAIL_SUCCESS:
      return action.response.result.name
    case VALIDATE_EMAIL_REQUEST:
    case VALIDATE_EMAIL_FAILURE:
    case CLEAR_CREDENTIALS:
    case ENTER_NEW_PASS_FAILURE:
      return ''
    default:
      return state
  }
}

const availableREALMLoginOptions = (state = [], action) => {
  switch (action.type) {
    case REALM_OPTIONS_SUCCESS:
      return action.response.result
    case REALM_OPTIONS_FAILURE:
      return {}
    default:
      return state
  }
}
const availableEnvLoginOptions = (state = [], action) => {
  switch (action.type) {
    case GET_OPTIONS_SUCCESS:
      return action.response.result
    case GET_OPTIONS_FAILURE:
      return []
    default:
      return state
  }
}
const credentialsAreValid = (state = false, action) => {
  switch (action.type) {
    case CREDENTIALS_SUCCESS:
      return true
    case CREDENTIALS_FAILURE:
      return false
    default:
      return state
  }
}

const consentInfo = (state = {}, action) => {
  switch (action.type) {
    case GET_CONSENT_SUCCESS:
      return action.response.result
    case GET_CONSENT_FAILURE:
      return {}
    default:
      return state
  }
}

const consentAccepted = (state = false, action) => {
  switch (action.type) {
    case ACCEPTANCE_SUCCESS:
      return true
    case ACCEPTANCE_FAILURE:
      return false
    default:
      return state
  }
}

const clickedEnterBtn = (state = false, action) => {
  switch (action.type) {
    case HANDLE_ENTER_CLICK:
      return true
    case HANDLED_ENTER_CLICK:
      return false
    default:
      return state
  }
}

const requestIsValid = (state = false, action) => {
  switch (action.type) {
    case CODE_SUCCESS:
    case TRIGGER_NEW_PASS_SUCCESS:
      return true
    case ACCEPTANCE_FAILURE:
    case GET_CONSENT_FAILURE:
    case CREDENTIALS_FAILURE:
    case GET_OPTIONS_FAILURE:
    case REALM_OPTIONS_FAILURE:
    case VALIDATE_EMAIL_FAILURE:
    case REALM_INFO_FAILURE:
    case DOMAIN_FAILURE:
    case TRIGGER_CODE_FAILURE:
    case TRIGGER_NEW_PASS_FAILURE:
    case CODE_FAILURE:
    case SET_ERROR_MESSAGE:
    case TRIGGER_NEW_PASS_REQUEST:
    case TRIGGER_CODE_REQUEST:
    case GET_CONSENT_REQUEST:
    case CREDENTIALS_REQUEST:
    case GET_OPTIONS_REQUEST:
    case REALM_OPTIONS_REQUEST:
    case VALIDATE_EMAIL_REQUEST:
    case REALM_INFO_REQUEST:
    case DOMAIN_REQUEST:
    case CLEAR_VALID_REQUEST:
      return false
    default:
      return state
  }
}

const errorMsg = (state = '', action) => {
  switch (action.type) {
    case ACCEPTANCE_SUCCESS:
    case GET_CONSENT_SUCCESS:
    case CREDENTIALS_SUCCESS:
    case GET_OPTIONS_SUCCESS:
    case REALM_OPTIONS_SUCCESS:
    case VALIDATE_EMAIL_SUCCESS:
    case REALM_INFO_SUCCESS:
    case DOMAIN_SUCCESS:
    case GET_CONSENT_REQUEST:
    case CREDENTIALS_REQUEST:
    case GET_OPTIONS_REQUEST:
    case REALM_OPTIONS_REQUEST:
    case VALIDATE_EMAIL_REQUEST:
    case REALM_INFO_REQUEST:
    case DOMAIN_REQUEST:
    case TRIGGER_CODE_SUCCESS:
    case CODE_SUCCESS:
    case TRIGGER_NEW_PASS_SUCCESS:
    case TRIGGER_NEW_PASS_REQUEST:
    case TRIGGER_CODE_REQUEST:
    case ENTER_NEW_PASS_SUCCESS:
    case CLEAR_ERROR_MESSAGE:
      return ''
    case ACCEPTANCE_FAILURE:
    case GET_CONSENT_FAILURE:
    case CREDENTIALS_FAILURE:
    case GET_OPTIONS_FAILURE:
    case REALM_OPTIONS_FAILURE:
    case VALIDATE_EMAIL_FAILURE:
    case REALM_INFO_FAILURE:
    case DOMAIN_FAILURE:
    case TRIGGER_CODE_FAILURE:
    case TRIGGER_NEW_PASS_FAILURE:
    case CODE_FAILURE:
    case SET_ERROR_MESSAGE:
    case ENTER_NEW_PASS_FAILURE:
      return action.message ?? ''
    default:
      return state
  }
}
const changedPassword = (state = false, action) => {
  switch (action.type) {
    case ENTER_NEW_PASS_SUCCESS:
      return true
    case ENTER_NEW_PASS_FAILURE:
      return false
    default:
      return state
  }
}
const setEmailValue = (state = '', action) => {
  switch (action.type) {
    case ENTER_NEW_PASS_SUCCESS:
      return action.response.result.userEmail
    case SET_EMAIL_VALUE:
      return action.email
    case CLEAR_CREDENTIALS:
    case ENTER_NEW_PASS_FAILURE:
      return ''
    default:
      return state
  }
}
const availableREALMS = (state = [], action) => {
  switch (action.type) {
    case GET_REALMS_SUCCESS:
      return action.response.result
    case GET_REALMS_FAILURE:
      return []
    default:
      return state
  }
}

const setSelectedApp = (state = '', action) => {
  switch (action.type) {
    case SET_SELECTED_APP:
      return action.app
    default:
      return state
  }
}

export default combineReducers({
  domainIsValid,
  realmInfo,
  credentialsAreValid,
  availableEnvLoginOptions,
  availableREALMLoginOptions,
  consentInfo,
  consentAccepted,
  userName,
  errorMsg,
  setEmailValue,
  requestIsValid,
  availableREALMS,
  changedPassword,
  clickedEnterBtn,
  setSelectedApp,
})
