import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'

import * as FormElements from 'app/components/form/FormElements'
import ResetPasswordPanel from 'app/components/reset_password/ResetPasswordPanel'
import Label from 'app/components/form/Label'
import Password from 'app/components/form/Password'
import SubmitButton from 'app/components/SubmitButton'
import {
  getUserName,
  getRealmInfo,
  getErrorMsg,
  getEmailValue,
  getChangedPassword,
} from 'app/pages/login/selectors'
import { setErrorMessage, postRecoveryPassword } from 'app/pages/login/actions'
import ErrorMsg from 'app/components/ErrorMsg'
import { successfulyChangedViewPath } from 'app/pages/login/constants'
import { errorMessages } from 'utils/errorMessages'
import { checkPassword } from 'utils/validations'

const StyledErrorMsg = styled(ErrorMsg)`
  display: block;
  padding: 1rem 0;
`
const StyledInfo = styled.p`
  font-size: 2.4rem;
  text-align: center;
  font-weight: 500;
`
const SelectNewPasswordForm = ({
  errorMessage,
  setErrorMessage,
  postRecoveryPassword,
  changedPassword,
  emailValue,
  userName,
  realmInfo,
}) => {
  const passwordKey = 'password'
  const secretParam = 'key'
  const realmUuidParam = 'realm_uuid'
  const realmAddressParam = 'hostname'
  const [formValues, setFormValues] = useState({
    password: '',
  })
  const [paramValues, setParamValues] = useState({
    secretParamValue: '',
    realmUuidParamValue: '',
    realmAddress: '',
  })
  const [visibility, setVisibility] = useState(false)
  const [showEnterPasswordPage, setShowEnterPasswordPage] = useState(false)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const removeQueryParams = () => {
    if (
      searchParams.has(secretParam) &&
      searchParams.has(realmUuidParam) &&
      searchParams.has(realmAddressParam)
    ) {
      setParamValues({
        secretParamValue: searchParams.get(secretParam),
        realmUuidParamValue: searchParams.get(realmUuidParam),
        realmAddress: searchParams.get(realmAddressParam),
      })
      searchParams.delete(secretParam)
      searchParams.delete(realmUuidParam)
      searchParams.delete(realmAddressParam)
    }
    setSearchParams(searchParams)
  }
  useEffect(() => {
    removeQueryParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !!paramValues.secretParamValue &&
      !!paramValues.realmUuidParamValue &&
      !!paramValues.realmAddress
    ) {
      setShowEnterPasswordPage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramValues])

  useEffect(() => {
    if (changedPassword) {
      navigate(successfulyChangedViewPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedPassword])

  const selectPassword = () => {
    if (formValues.password) {
      if (checkPassword(formValues.password)) {
        postRecoveryPassword({
          password: formValues.password,
          key: paramValues.secretParamValue,
          realmUuid: paramValues.realmUuidParamValue,
          realmAddress: paramValues.realmAddress,
        })
      } else {
        setErrorMessage({
          message: errorMessages.enterNewPassword.newPassFormat,
        })
      }
    } else {
      setErrorMessage({
        message: errorMessages.enterNewPassword.newPassFormat,
      })
    }
  }
  const pageContent = () => (
    <ResetPasswordPanel>
      <FormElements.LoginRow>
        <Label htmlFor={passwordKey}>New Password</Label>
        <Password
          name={passwordKey}
          required
          visible={visibility}
          value={formValues[passwordKey]}
          toggleVisibility={() => setVisibility(!visibility)}
          onChange={(event) =>
            setFormValues({
              password: event.target.value,
            })
          }
        />
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        {errorMessage && (
          <StyledErrorMsg>
            {errorMessage !== errorMessages.changeErrorMessage
              ? errorMessage
              : errorMessages.generalErrorMessage}
          </StyledErrorMsg>
        )}
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={selectPassword} primary>
          Confirm
        </SubmitButton>
      </FormElements.LoginRow>
    </ResetPasswordPanel>
  )
  const notAvailable = () => {
    return (
      <StyledInfo>
        Please, open the provided link for changing your password.
      </StyledInfo>
    )
  }

  return (
    <>
      {showEnterPasswordPage && pageContent()}
      {!showEnterPasswordPage && notAvailable()}
    </>
  )
}
SelectNewPasswordForm.propTypes = {
  setErrorMessage: PropTypes.func,
  errorMessage: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  postRecoveryPassword: PropTypes.func,
  emailValue: PropTypes.string,
  userName: PropTypes.string,
  realmInfo: PropTypes.object,
  changedPassword: PropTypes.bool,
}
const mapStateToProps = (state) => ({
  errorMessage: getErrorMsg(state),
  realmInfo: getRealmInfo(state),
  userName: getUserName(state),
  emailValue: getEmailValue(state),
  changedPassword: getChangedPassword(state),
})

export default connect(mapStateToProps, {
  setErrorMessage,
  postRecoveryPassword,
})(SelectNewPasswordForm)
