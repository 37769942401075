import { createSelector } from 'reselect'

export const makeSelector = (selector) =>
  createSelector([selector], (selector) => selector)
const domainIsValid = (state) => state.getIn(['login', 'domainIsValid'])
const realmInfo = (state) => state.getIn(['login', 'realmInfo'])
const availableEnvLoginOptions = (state) =>
  state.getIn(['login', 'availableEnvLoginOptions'])
const credentialsAreValid = (state) =>
  state.getIn(['login', 'credentialsAreValid'])
const availableREALMLoginOptions = (state) =>
  state.getIn(['login', 'availableREALMLoginOptions'])
const consentInfo = (state) => state.getIn(['login', 'consentInfo'])
const consentAccepted = (state) => state.getIn(['login', 'consentAccepted'])
const userName = (state) => state.getIn(['login', 'userName'])
const errorMsg = (state) => state.getIn(['login', 'errorMsg'])
const setEmailValue = (state) => state.getIn(['login', 'setEmailValue'])
const requestIsValid = (state) => state.getIn(['login', 'requestIsValid'])
const availableREALMS = (state) => state.getIn(['login', 'availableREALMS'])
const changedPassword = (state) => state.getIn(['login', 'changedPassword'])
const clickedEnterBtn = (state) => state.getIn(['login', 'clickedEnterBtn'])
const selectedApp = (state) => state.getIn(['login', 'setSelectedApp'])

export const getIsDomainValid = createSelector(
  [domainIsValid],
  (domainIsValid) => domainIsValid
)
export const getRealmInfo = createSelector(
  [realmInfo],
  (realmInfo) => realmInfo
)

export const getCredentialsAreValid = createSelector(
  [credentialsAreValid],
  (credentialsAreValid) => credentialsAreValid
)

export const getAvailableEnvLoginOptions = createSelector(
  [availableEnvLoginOptions],
  (availableEnvLoginOptions) => availableEnvLoginOptions
)
export const getAvailableRealmLoginOptions = createSelector(
  [availableREALMLoginOptions],
  (availableREALMLoginOptions) => availableREALMLoginOptions
)

export const getConsentInfo = createSelector(
  [consentInfo],
  (consentInfo) => consentInfo
)
export const getConsentAccepted = createSelector(
  [consentAccepted],
  (consentAccepted) => consentAccepted
)
export const getUserName = createSelector([userName], (userName) => userName)
export const getErrorMsg = createSelector([errorMsg], (errorMsg) => errorMsg)
export const getRequestIsValid = createSelector(
  [requestIsValid],
  (requestIsValid) => requestIsValid
)
export const getEmailValue = createSelector(
  [setEmailValue],
  (setEmailValue) => setEmailValue
)
export const getAvailableREALMS = createSelector(
  [availableREALMS],
  (availableREALMS) => availableREALMS
)
export const getChangedPassword = createSelector(
  [changedPassword],
  (changedPassword) => changedPassword
)
export const getClickedEnterBtn = createSelector(
  [clickedEnterBtn],
  (clickedEnterBtn) => clickedEnterBtn
)
export const getSelectedApp = createSelector(
  [selectedApp],
  (selectedApp) => selectedApp
)
