import styled, { css } from 'styled-components'

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelBgLight};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadow};
  overflow: hidden;

  ${(props) =>
    props.isForm &&
    css`
      margin: 20px;
      max-width: 640px;
    `};
`
export const LoginPanelHolder = styled(Panel)`
  position: absolute;
  height: 39rem;
  width: 60rem;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 6px;
  padding: 0 0 20px 0;
  top: 22rem;
  background-color: #fffcfa;
`

export const LoginLogo = styled.div`
  margin: 9.5rem auto 4.5rem auto;
  width: 20rem;
  height: 2.2rem;
  background-image: url('${process.env.PUBLIC_URL}/logo-black.svg');
  background-size: cover;
`
export const LoginCaption = styled.span`
  font-size: 30px;
  font-weight: bold;
  left: 0;
  right: 0;
  margin: 30px auto;
`
export const Header = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  left: 0;
  right: 0;
  margin: ${(props) => (props.margin ? props.margin : '10rem auto 3rem auto')};
`
export const Divider = styled.div`
  width: 100%;
  margin: 0 !important;
  height: ${(props) => (props.height ? props.height : '0.1rem')};
`
export const ButtonsHolder = styled.div`
  overflow: auto;
  text-align: center;
  height: 100%;
`
export const buttonPngWidth = '22rem'
export const buttonPngHeight = '5rem'

export default Panel
