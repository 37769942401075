import styled from 'styled-components'

export const WhiteBgr = styled.div`
  background-color: white;
  width: 100%;
  height: auto;
`
export const IconHolder = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
  img {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
`
export const Header = styled.p`
  margin: 3rem 0 4.5rem 0;
  font-size: 2rem;
  text-align: center;
  color: #2f3437;
`
