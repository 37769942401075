import styled from 'styled-components'

import Row from 'app/components/form/Row'
import Icon from 'app/components/Icon'
import { icons } from 'app/components/icon/icons'

export const LoginRow = styled(Row)`
  width: 350px;
  display: block;
  margin: 0 auto;
  > label {
    margin: 1rem 0;
  }
  > div {
    margin: 1rem 0;
  }
  > button {
    // width: 70%;
    margin: 1rem auto;
    display: block;
  }
`
const Button = styled.div`
  display: flex;
  margin: 2rem 1rem 6rem 1rem;
  align-items: center;
  font-weight: 500;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '6rem'};
  :hover {
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 1.5rem;
  }
  i {
    font-weight: 700;
    font-size: 2.5rem;
    padding-top: 3px;
  }
`

export const BackButton = ({ onClick, marginBottom }) => {
  return (
    <Button marginBottom={marginBottom} onClick={onClick}>
      <span>
        <Icon name={icons.LEFT_ARROW} />
      </span>
      <span>Back</span>
    </Button>
  )
}
