import React, { Component } from 'react'

import { LoginLogo } from 'app/components/Panel.jsx'
import LoginPanel from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import LoginInit from 'app/pages/login/LoginInit.jsx'

class Login extends Component {
  state = {
    showValidateCompanyModal: false,
  }
  componentDidMount() {
    window.localStorage.setItem('applicationBase', window.location)
  }

  render() {
    return (
      <>
        <LoginPanel>
          {!this.state.showValidateCompanyModal && <LoginLogo />}
          <LoginInit
            hideLoginTitle={(value) =>
              this.setState({
                showValidateCompanyModal: value,
              })
            }
          />
          <FlexSpacer />
        </LoginPanel>
      </>
    )
  }
}

export default Login
