import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { setEnterHandled } from 'app/pages/login/actions'
import InlineLoading from './InlineLoading'
import { getClickedEnterBtn } from 'app/pages/login/selectors'

export const StyledButton = styled.button`
  font: inherit;
  width: 30rem;
  cursor: pointer;
  font-size: 1.8rem;
  background-color: #424d61;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 0;
  display: inline-block;
  :hover {
    filter: brightness(120%);
  }
`
const SubmitButton = ({
  submitting,
  disabled,
  children,
  onClick,
  setEnterHandled,
  type = 'submit',
  handleEnterEvent,
  ...rest
}) => {
  useEffect(() => {
    if (handleEnterEvent) {
      onClick()
      setEnterHandled()
    }
  }, [handleEnterEvent]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledButton
      onClick={onClick}
      type={type}
      disabled={submitting || disabled}
      {...rest}
    >
      {submitting ? <InlineLoading /> : children}
    </StyledButton>
  )
}

SubmitButton.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onClick: PropTypes.func,
  handleEnterEvent: PropTypes.bool,
  type: PropTypes.string,
  setEnterHandled: PropTypes.func,
}

const mapStateToProps = (state) => ({
  handleEnterEvent: getClickedEnterBtn(state),
})

export default connect(mapStateToProps, { setEnterHandled })(SubmitButton)
