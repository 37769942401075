import React from 'react'
import styled from 'styled-components'

import { Header } from 'app/components/Panel.jsx'
import LoginPanel from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import ConsentView from 'app/pages/login/ConsentView'

export const StyledHeader = styled(Header)`
  font-weight: 500;
  margin: 5rem auto 0rem;
`

const ConsentInfo = () => {
  return (
    <LoginPanel>
      <StyledHeader>User consent</StyledHeader>
      <ConsentView />
      <FlexSpacer />
    </LoginPanel>
  )
}

export default ConsentInfo
