import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  realmInfoRequest,
  getConsent,
  postConsentAcceptance,
  clearRealmInfo,
} from 'app/pages/login/actions'
import {
  getRealmInfo,
  getConsentInfo,
  getConsentAccepted,
} from 'app/pages/login/selectors'
import * as FormElements from 'app/components/form/FormElements'
import SubmitButton from 'app/components/SubmitButton'
import { Divider } from 'app/components/Panel.jsx'

const LayoutHolder = styled.div`
  position: relative;
  height: 26rem;
  margin: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TextHolder = styled.p`
  width: 100%;
  height: 20rem;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 2rem;
  white-space: pre-line;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fffcfa;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fffcfa;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`
const ConsentView = ({
  realmInfoRequest,
  realmInfo,
  consentInfo,
  postConsentAcceptance,
  isConsentAccepted,
  clearRealmInfo,
  getConsent,
}) => {
  useEffect(() => {
    clearRealmInfo()
    realmInfoRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getConsentData = useCallback(() => {
    const realmAddress = realmInfo.address
    if (realmAddress) getConsent(realmAddress)
  }, [realmInfo, getConsent])

  useEffect(() => {
    getConsentData()
  }, [realmInfo, getConsentData])

  useEffect(() => {
    if (isConsentAccepted) {
      const realmAddress = realmInfo.address
      const successful_login_url = `${realmAddress}/concierge/session/redirect`
      window.location = successful_login_url
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentAccepted])

  const handleSubmit = () => {
    const realmAddress = realmInfo.address

    postConsentAcceptance(realmAddress, consentInfo.uuid)
  }

  if (!consentInfo.uuid)
    return (
      <FormElements.LoginRow>
        <p>Loading...</p>
      </FormElements.LoginRow>
    )
  return (
    <LayoutHolder>
      <TextHolder>{consentInfo.text}</TextHolder>
      <Divider height={'1.2rem'} />
      <SubmitButton onClick={() => handleSubmit()} primary>
        I Accept
      </SubmitButton>
    </LayoutHolder>
  )
}

ConsentView.propTypes = {
  realmInfoRequest: PropTypes.func,
  realmInfo: PropTypes.object,
  consentInfo: PropTypes.object,
  postConsentAcceptance: PropTypes.func,
  isConsentAccepted: PropTypes.bool,
  clearRealmInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  consentInfo: getConsentInfo(state),
  isConsentAccepted: getConsentAccepted(state),
})

export default connect(mapStateToProps, {
  realmInfoRequest,
  getConsent,
  postConsentAcceptance,
  clearRealmInfo,
})(ConsentView)
