import React from 'react'
import styled from 'styled-components'
import * as microsoftTeams from '@microsoft/teams-js';

import { LoginButton } from 'app/components/Button'
import { buttonPngWidth, buttonPngHeight } from 'app/components/Panel'

import { config } from '../api/public'

export const PngImage = styled.img`
  width: ${buttonPngWidth};
  height: ${buttonPngHeight};
`

export const OktaBtn = styled(LoginButton)`
  width: ${buttonPngWidth};
  background-color: #fff;
  color: #424d61;
  text-transform: none;
  display: block;
  border: 1px solid #f6f6f6;
  box-shadow: 2px 2px 0.12rem 0px #f6f6f6;
`

export const GoogleBtn = (props) => {
  const { type } = props

  const handleGoogleOAuth = async () => {
    const central_host = await config('central_hostname')
    const api_host =
      process.env.NODE_ENV === 'production'
        ? window.location.host
        : central_host
    const login_url = `https://${api_host}/reception/google/start`
    window.location = login_url
  }
  const Google_Login = `${process.env.PUBLIC_URL}/google.png`
  return (
    <LoginButton key={type} onClick={handleGoogleOAuth} primary>
      <PngImage src={Google_Login} alt='Google logo' />
    </LoginButton>
  )
}

export const MSBtn = (props) => {
  const { type } = props

  const handleMicrosoftOAuth = async () => {
    const central_host = await config('central_hostname')
    const api_host =
      process.env.NODE_ENV === 'production'
        ? window.location.host
        : central_host
    const login_url = `https://${api_host}/reception/microsoft/start`
    const popupWidth = 600
    const popupHeight = 535
    if (window.microsoftTeams) {
      microsoftTeams.initialize();
      microsoftTeams.authentication.authenticate({
        url: login_url,
        width: popupWidth,
        height: popupHeight,
        successCallback: (result) => {
          console.log('Authentication succeeded', result);
        },
        failureCallback: (error) => {
          console.error('Authentication failed', error);
        }
      });
    } else {
      window.location = login_url;
    }
  }
  const MS_Login = `${process.env.PUBLIC_URL}/ms-signin.svg`
  return (
    <LoginButton key={type} onClick={() => handleMicrosoftOAuth()}>
      <img src={MS_Login} alt='Microsoft OAuth Logo' />
    </LoginButton>
  )
}

export const OPENIDBtn = (props) => {
  const { realmInfo, type, tenantUuid } = props
  const handleOpenIdOAuth = async () => {
    const realmAddress = realmInfo.address
    const login_url = `${realmAddress}/concierge/auth/openid/start/${tenantUuid}`
    window.location = login_url
  }
  const openId_Login = `${process.env.PUBLIC_URL}/open_id.png`
  return (
    <LoginButton key={type} onClick={handleOpenIdOAuth} primary>
      <PngImage src={openId_Login} alt='OpenId logo' />
    </LoginButton>
  )
}

export const OKTABtn = (props) => {
  const { realmInfo, type, tenantUuid } = props
  const handleOktaOAuth = async () => {
    const realmAddress = realmInfo.address
    const login_url = `${realmAddress}/concierge/auth/okta/start/${tenantUuid}`
    window.location = login_url
  }

  return (
    <OktaBtn key={type} onClick={handleOktaOAuth} primary>
      Sign in with Okta
    </OktaBtn>
  )
}
