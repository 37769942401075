import {
  DOMAIN_REQUEST,
  DOMAIN_SUCCESS,
  DOMAIN_FAILURE,
  CREDENTIALS_FAILURE,
  CREDENTIALS_SUCCESS,
  REALM_INFO_REQUEST,
  REALM_INFO_SUCCESS,
  REALM_INFO_FAILURE,
  REALM_OPTIONS_REQUEST,
  REALM_OPTIONS_SUCCESS,
  REALM_OPTIONS_FAILURE,
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  GET_CONSENT_REQUEST,
  GET_CONSENT_SUCCESS,
  GET_CONSENT_FAILURE,
  ACCEPTANCE_SUCCESS,
  ACCEPTANCE_FAILURE,
  VALIDATE_EMAIL_REQUEST,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  SET_ERROR_MESSAGE,
  SET_EMAIL_VALUE,
  CLEAR_CREDENTIALS,
  CLEAR_ERROR_MESSAGE,
  TRIGGER_CODE_REQUEST,
  TRIGGER_CODE_SUCCESS,
  TRIGGER_CODE_FAILURE,
  TRIGGER_NEW_PASS_REQUEST,
  TRIGGER_NEW_PASS_SUCCESS,
  TRIGGER_NEW_PASS_FAILURE,
  CODE_SUCCESS,
  CODE_FAILURE,
  CLEAR_VALID_REQUEST,
  GET_REALMS_FAILURE,
  GET_REALMS_SUCCESS,
  GET_REALMS_REQUEST,
  ENTER_NEW_PASS_FAILURE,
  ENTER_NEW_PASS_SUCCESS,
  HANDLE_ENTER_CLICK,
  HANDLED_ENTER_CLICK,
  SET_SELECTED_APP,
} from './constants'
import * as genericRestActions from 'utils/genericRestActions'
import { errorMessages } from 'utils/errorMessages'

export const setErrorMessage =
  ({ message = errorMessages.changeErrorMessage } = {}) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_MESSAGE,
      message,
    })
  }

export const clearErrorMessage = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR_MESSAGE,
  })
}

export const clearValidRequest = () => (dispatch) => {
  dispatch({
    type: CLEAR_VALID_REQUEST,
  })
}

export const setEmailValue =
  ({ email }) =>
  (dispatch) => {
    dispatch({
      type: SET_EMAIL_VALUE,
      email,
    })
  }

export const setEnterClicked = () => (dispatch) => {
  dispatch({
    type: HANDLE_ENTER_CLICK,
  })
}
export const setEnterHandled = () => (dispatch) => {
  dispatch({
    type: HANDLED_ENTER_CLICK,
  })
}

export const setSelectedApp = (selectedApp) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_APP,
    app: selectedApp,
  })
}

export const domainRequest = (knownBy) => {
  return genericRestActions.getNoContent({
    constants: {
      FETCH_REQUEST: DOMAIN_REQUEST,
      FETCH_SUCCESS: DOMAIN_SUCCESS,
      FETCH_FAILURE: DOMAIN_FAILURE,
    },
    endpoint: '/realm/validate',
    name: 'get domain',
    params: knownBy,
  })
}

export const getEnvOption = (realmAddress) => {
  return genericRestActions.getExternal({
    constants: {
      FETCH_REQUEST: GET_OPTIONS_REQUEST,
      FETCH_SUCCESS: GET_OPTIONS_SUCCESS,
      FETCH_FAILURE: GET_OPTIONS_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/session/options`,
    name: 'get options',
    token: '',
    params: '',
  })
}

export const getConsent = (realmAddress) => {
  return genericRestActions.getExternal({
    constants: {
      FETCH_REQUEST: GET_CONSENT_REQUEST,
      FETCH_SUCCESS: GET_CONSENT_SUCCESS,
      FETCH_FAILURE: GET_CONSENT_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/consent`,
    name: 'get realm info',
    params: '',
  })
}
export const postConsentAcceptance = (realmAddress, consentUuid) => {
  return genericRestActions.postExternal({
    constants: {
      POST_SUCCESS: ACCEPTANCE_SUCCESS,
      POST_FAILURE: ACCEPTANCE_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/consent/${consentUuid}`,
    name: 'Post credentials',
    item: {},
    getsJSON: false,
  })
}

export const postCredentials = (realmAddress, credentials) => {
  return genericRestActions.postExternal({
    constants: {
      POST_SUCCESS: CREDENTIALS_SUCCESS,
      POST_FAILURE: CREDENTIALS_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/session/credential`,
    name: 'Post credentials',
    item: credentials,
    getsJSON: false,
  })
}

export const postCode = (realmAddress, code) => {
  return genericRestActions.postExternal({
    constants: {
      POST_SUCCESS: CODE_SUCCESS,
      POST_FAILURE: CODE_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/verification-code/authenticate`,
    name: 'Post code',
    item: code,
    getsJSON: false,
  })
}

export const validateEmail = (realmAddress, email) => {
  return genericRestActions.getExternal({
    constants: {
      FETCH_REQUEST: VALIDATE_EMAIL_REQUEST,
      FETCH_SUCCESS: VALIDATE_EMAIL_SUCCESS,
      FETCH_FAILURE: VALIDATE_EMAIL_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/email-validate`,
    name: 'Validate email',
    params: { email },
  })
}
export const triggerCode = (realmAddress, email) => {
  return genericRestActions.getExternalNoContent({
    constants: {
      FETCH_REQUEST: TRIGGER_CODE_REQUEST,
      FETCH_SUCCESS: TRIGGER_CODE_SUCCESS,
      FETCH_FAILURE: TRIGGER_CODE_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/verification-code/trigger`,
    name: 'Trigger verification code',
    params: { email },
  })
}

export const triggerNewPassword = (realmAddress, email) => {
  return genericRestActions.getExternalNoContent({
    constants: {
      FETCH_REQUEST: TRIGGER_NEW_PASS_REQUEST,
      FETCH_SUCCESS: TRIGGER_NEW_PASS_SUCCESS,
      FETCH_FAILURE: TRIGGER_NEW_PASS_FAILURE,
    },
    endpoint: `${realmAddress}/concierge/recovery`,
    name: 'Trigger new passowrd',
    params: { email },
  })
}

export const clearCredentials = () => ({
  type: CLEAR_CREDENTIALS,
})
export const clearRealmInfo = () => ({
  type: REALM_INFO_FAILURE,
})
export const realmInfoRequest = () => {
  return genericRestActions.get({
    constants: {
      FETCH_REQUEST: REALM_INFO_REQUEST,
      FETCH_SUCCESS: REALM_INFO_SUCCESS,
      FETCH_FAILURE: REALM_INFO_FAILURE,
    },
    endpoint: '/realm/environment',
    name: 'get realm info',
    params: '',
  })
}

export const realmOptionsRequest = () => {
  return genericRestActions.get({
    constants: {
      FETCH_REQUEST: REALM_OPTIONS_REQUEST,
      FETCH_SUCCESS: REALM_OPTIONS_SUCCESS,
      FETCH_FAILURE: REALM_OPTIONS_FAILURE,
    },
    endpoint: '/realm/options',
    name: 'get realm info',
    params: '',
  })
}

export const getAvailableRealms = () => {
  return genericRestActions.get({
    constants: {
      FETCH_REQUEST: GET_REALMS_REQUEST,
      FETCH_SUCCESS: GET_REALMS_SUCCESS,
      FETCH_FAILURE: GET_REALMS_FAILURE,
    },
    endpoint: '/realm/select',
    name: 'get available realms',
    params: '',
  })
}

export const postRecoveryPassword = ({
  password,
  realmUuid,
  key,
  realmAddress,
}) => {
  return genericRestActions.postExternal({
    constants: {
      POST_SUCCESS: ENTER_NEW_PASS_SUCCESS,
      POST_FAILURE: ENTER_NEW_PASS_FAILURE,
    },
    endpoint: `https://${realmAddress}/concierge/recovery?key=${key}&realm_uuid=${realmUuid}`,
    name: 'Post recovery password',
    item: { password },
  })
}
