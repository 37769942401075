import React from 'react'
import { SnackbarProvider } from 'notistack'

import AppRoutes from 'Routes'
import GlobalStyle from 'global-styles'
import { SnackbarUtilsConfigurator } from 'utils/snackbar'
import { BackgroundHolder } from './app/components/BackgroundHolder'
import { getAppData } from './utils/localStorage'
import { portalName } from './app/pages/login/LoginInit'

const ALERT_TIMEOUT = 5 * 1000 // milliseconds

const styles = {
  snackBarBase: {
    fontSize: '1.0em',
    verticalAlign: 'center',
    lineHeight: '2em',
  },
}

function App() {
  const isPortal = getAppData() === portalName
  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={ALERT_TIMEOUT}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      classes={{
        root: styles.snackBarBase,
      }}
    >
      <SnackbarUtilsConfigurator />
      <BackgroundHolder isPortal={isPortal} />
      <AppRoutes />
      <GlobalStyle />
    </SnackbarProvider>
  )
}

export default App
