import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styled from 'styled-components'

import { setEnterClicked } from 'app/pages/login/actions'
const enterKeyCode = 13

const ResetPasswordHolder = styled.div`
  width: 100%;
  height: 100%;
`

const ResetPasswordPanel = ({ setEnterClicked, children }) => {
  const handleEnterClicked = (e) => {
    if (e.keyCode === enterKeyCode) {
      setEnterClicked()
    }
  }
  return (
    <ResetPasswordHolder onKeyUpCapture={handleEnterClicked}>
      {children}
    </ResetPasswordHolder>
  )
}

ResetPasswordPanel.propTypes = {
  children: PropTypes.array,
  setEnterClicked: PropTypes.func,
}

export default connect(null, {
  setEnterClicked,
})(ResetPasswordPanel)
