import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  WhiteBgr,
  IconHolder,
  Header,
} from 'app/components/reset_password/Panel.jsx'
import SelectNewPasswordForm from 'app/pages/reset_password/SelectNewPasswordForm.jsx'
import ConfirmChangedPassword from 'app/pages/reset_password/ConfirmChangedPassword.jsx'
import {
  enterPassViewPath,
  successfulyChangedViewPath,
} from 'app/pages/login/constants'

const ChangePassword = ({ showPage }) => {
  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps
  const password_icon = `${process.env.PUBLIC_URL}/password.svg`
  const logo_icon = `${process.env.PUBLIC_URL}/logo-orange.svg`

  const currentPage = () => {
    switch (showPage) {
      case enterPassViewPath:
        return (
          <>
            <Header>Reset your Flowscape Password</Header>
            <SelectNewPasswordForm />
          </>
        )
      case successfulyChangedViewPath:
        return (
          <>
            <Header>Your password has been changed!</Header>
            <ConfirmChangedPassword />
          </>
        )
      default:
        return (
          <>
            <Header>Reset your Flowscape Password</Header>
            <SelectNewPasswordForm />
          </>
        )
    }
  }

  return (
    <WhiteBgr>
      <IconHolder marginTop='15rem' width='7.5rem' height='7.5rem'>
        <img src={password_icon} alt='Password icon' />
      </IconHolder>
      <IconHolder marginTop='6.5rem' width='20rem' height='3rem'>
        <img src={logo_icon} alt='Logo icon' />
      </IconHolder>
      {currentPage()}
    </WhiteBgr>
  )
}
ChangePassword.propTypes = {
  showPage: PropTypes.string,
}

export default ChangePassword
