import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import * as FormElements from 'app/components/form/FormElements'
import Label from 'app/components/form/Label'
import { Divider } from 'app/components/Panel.jsx'
import Input from 'app/components/form/Input'
import SubmitButton from 'app/components/SubmitButton'
import { realmViewPath, passViewPath } from 'app/pages/login/constants'
import {
  getUserName,
  getRealmInfo,
  getErrorMsg,
} from 'app/pages/login/selectors'
import {
  validateEmail,
  setErrorMessage,
  setEmailValue,
  clearCredentials,
  clearErrorMessage,
} from 'app/pages/login/actions'
import ErrorMsg from 'app/components/ErrorMsg'
import { errorMessages } from 'utils/errorMessages'
import { checkEmail } from 'utils/validations'

const EmailForm = ({
  realmInfo,
  validateEmail,
  userName,
  errorMessage,
  setErrorMessage,
  setEmailValue,
  clearCredentials,
}) => {
  const emailKey = 'knownBy'
  let emailValue = ''
  const navigate = useNavigate()

  useEffect(() => {
    if (userName) {
      clearErrorMessage()
      navigate(passViewPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName])

  const openOptionsPath = () => {
    clearCredentials()
    navigate(realmViewPath)
  }

  const sendEmail = () => {
    const realmAddress = realmInfo.address
    if (emailValue && checkEmail(emailValue)) {
      validateEmail(realmAddress, emailValue)
      setEmailValue({ email: emailValue })
    } else {
      setErrorMessage({ message: errorMessages.enterEmail.wrongFormat })
    }
  }

  return (
    <>
      <FormElements.BackButton marginBottom='8rem' onClick={openOptionsPath} />

      <FormElements.LoginRow>
        <Label htmlFor={emailKey}>Email</Label>
        <Input
          name={emailKey}
          type='text'
          autoComplete={emailKey}
          autoFocus
          required
          onChange={(event) => (emailValue = event.target.value)}
        />
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        {errorMessage && (
          <ErrorMsg>
            {errorMessage !== errorMessages.changeErrorMessage
              ? errorMessage
              : errorMessages.enterEmail.userDoesNotExist}
          </ErrorMsg>
        )}
        {!errorMessage && <Divider height={'1.2rem'} />}
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={sendEmail} primary>
          Next
        </SubmitButton>
      </FormElements.LoginRow>
      {/* </form> */}
    </>
  )
}
EmailForm.propTypes = {
  realmInfo: PropTypes.object,
  userName: PropTypes.string,
  errorMessage: PropTypes.string,
  validateEmail: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setEmailValue: PropTypes.func,
  clearCredentials: PropTypes.func,
  clearErrorMessage: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  userName: getUserName(state),
  errorMessage: getErrorMsg(state),
})

export default connect(mapStateToProps, {
  validateEmail,
  setErrorMessage,
  setEmailValue,
  clearCredentials,
  clearErrorMessage,
})(EmailForm)
