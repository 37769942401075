import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { config } from '../api/public'
import { saveAppData } from 'utils/localStorage'

const Initiate = ({ overrideAddress, selectedApp }) => {
  useEffect(() => {
    saveAppData(selectedApp)
    generateSession()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const generateSession = async () => {
    const central_host = await config('central_hostname')
    const api_host =
      process.env.NODE_ENV === 'production'
        ? window.location.host
        : central_host

    window.location = `https://${api_host}/reception/initiate?app_name=${selectedApp}${overrideAddress}`
  }
  return <></>
}
Initiate.propTypes = {
  selectedApp: PropTypes.string,
  overrideAddress: PropTypes.string,
}
export default Initiate
