import React, { PureComponent } from 'react'
import styled from 'styled-components'

export const StyledButton = styled.button`
  font: inherit;
  cursor: pointer;
  font-size: 1.4rem;
  background-color: ${(props) => props.theme.loginButton};
  color: ${(props) => props.theme.actionButton};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 0 20px;
  line-height: 36px;
  margin-right: 12px;
  display: inline-block;
  white-space: pre;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;

  :disabled {
    background: ${(props) => props.theme.disabledButtonBg};
    color: ${(props) => props.theme.disabledButton};
    box-shadow: none;
    cursor: default;
  }
`
export const StyledLink = styled.p`
  color: #1a1515;
  font-weight: 500;
  font-size: 1.4rem;
  margin: 0 auto;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

class Button extends PureComponent {
  render() {
    return (
      <StyledButton
        ref={(button) => {
          this.button = button
        }}
        tabIndex='0'
        {...this.props}
      />
    )
  }
}

export const LoginButton = styled(Button)`
  background-color: transparent;
  left: 0;
  right: 0;
  width: 31.9rem;
  height: 4rem;
  margin: 0 auto 2rem auto;
`
export default Button
