import React, { useEffect } from 'react'

import { Header } from 'app/components/Panel.jsx'
import LoginPanel from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import LoginRealm from 'app/pages/login/LoginRealm'

const RealmInfo = () => {
  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoginPanel>
      <Header margin={'13rem auto 3rem auto'}>
        Please select a login option:
      </Header>
      <LoginRealm />
      <FlexSpacer />
    </LoginPanel>
  )
}

export default RealmInfo
