import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { StyledLink } from 'app/components/Button'
import DomainForm from 'app/pages/login/DomainForm'
import {
  domainRequest,
  clearErrorMessage,
  setSelectedApp,
} from 'app/pages/login/actions'
import { getIsDomainValid, getSelectedApp } from 'app/pages/login/selectors'
import { realmViewPath } from 'app/pages/login/constants'
import { GoogleBtn, MSBtn } from 'app/components/LoginWithBtns'
import { Header } from 'app/components/Header'
import { getAppData } from 'utils/localStorage'

export const portalName = 'portal'

const appTitle = {
  portal: portalName,
  webapp: 'web app',
}
const LoginInit = ({
  domainRequest,
  isDomainValid,
  hideLoginTitle,
  clearErrorMessage,
  setSelectedApp,
  selectedApp,
}) => {
  const [showDomainModal, setShowDomainModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const appName = getAppData()
    setSelectedApp(appTitle[appName])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    goToRealmPage(isDomainValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainValid])

  const goToRealmPage = (isDomainValid) => {
    if (isDomainValid) {
      clearErrorMessage()
      return navigate(realmViewPath)
    }
  }
  const handleDomain = (values) => {
    domainRequest({ ...values })
  }

  const openDomainModal = () => {
    setShowDomainModal(true)
    hideLoginTitle(true)
  }

  const hideDomainModal = () => {
    setShowDomainModal(false)
    hideLoginTitle(false)
  }

  const createLoginButton = (loginType) => {
    if (showDomainModal) {
      return (
        <DomainForm
          key={`${loginType}3`}
          handleSubmit={handleDomain}
          hideLoginTitle={hideDomainModal}
        />
      )
    }
    return (
      <>
        <Header>{selectedApp}</Header>
        <MSBtn key={`MS${loginType}`} type={`MS${loginType}`} />
        <GoogleBtn key={`Google${loginType}`} type={`Google${loginType}`} />
        <StyledLink key={loginType} onClick={openDomainModal}>
          More options
        </StyledLink>
      </>
    )
  }

  return createLoginButton()
}

LoginInit.propTypes = {
  hideLoginTitle: PropTypes.func,
  domainRequest: PropTypes.func,
  isDomainValid: PropTypes.bool,
  selectedApp: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  setSelectedApp: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isDomainValid: getIsDomainValid(state),
  selectedApp: getSelectedApp(state),
})

export default connect(mapStateToProps, {
  domainRequest,
  clearErrorMessage,
  setSelectedApp,
})(LoginInit)
