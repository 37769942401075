import * as reduxToolkit from '@reduxjs/toolkit'
import { fromJS } from 'immutable'
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import { loadState } from './utils/localStorage'

const store = () => {
  const persistedState = fromJS(loadState())

  const store = reduxToolkit.configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk),
    preloadedState: persistedState,
  })

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export default store
