export const checkPassword = (pass) => {
  const passRequirements =
    /^(?=.*\d)(?=.*[!@#$%^&*+])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
  return passRequirements.test(pass)
}

export const checkEmail = (email) => {
  const emailRequirements = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  return emailRequirements.test(email)
}
